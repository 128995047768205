.date-picker-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    width: 272px;
    position: relative;

    &-icon {
      cursor: pointer;
      color: #AD3928;
      position: absolute;
      right: 24px; } }


.react-datepicker {
  margin-top: 5px;
  width: 272px;
  padding: 16px 16px 64px 16px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  text-transform: capitalize;
  font-family: Roboto, sans-serif;
  position: relative;
  color: #141414;
  &-popper[data-placement^=bottom] {
    padding: 0; }
  &__triangle {
    display: none; }

  &__input {
    &-container > input {
      height: 56px;
      width: 272px;
      border-radius: 8px;
      border: none;
      outline: none;
      padding: 0 24px;
      font-size: 16px;
      line-height: 24px; }
    &-time-container {
      margin: 12px 0;
      display: flex;
      align-items: center;
      justify-content: space-around; } }

  &__navigation {
    top: 18px;
    &--previous {
      left: 18px; }
    &--next {
      right: 18px; } }

  &__header {
    background: #FFFFFF;
    border-bottom: 1px solid #E2E2E2; }
  &__month {
    margin: 4px 0; }
  &__current-month {
    line-height: 24px;
    height: 48px;
    font-size: 16px;
    font-weight: 400; }

  &__day {
    height: 32px;
    line-height: 32px;
    width: 32px;
    font-weight: 400;
    margin: 1px;
    &-name {
      height: 32px;
      width: 32px;
      margin: 1px; }
    &--today {
      color: #AD3928; }
    &:hover {
      border-radius: 24px;
      height: 24px;
      width: 26px;
      line-height: 24px;
      margin: 0 4px; }
    &--outside-month {
      color: #CBCBCB; }
    &--selected {
      border-radius: 24px;
      background: #C15747;
      height: 24px;
      width: 26px;
      line-height: 24px;
      margin: 0 4px;
      color: #FFFFFF;
      &:hover {
        background: #8c3b2f;
        border-radius: 24px; } }
    &--keyboard-selected {
      border-radius: 24px;
      background: #C15747;
      height: 24px;
      width: 26px;
      line-height: 24px;
      margin: 0 4px;
      color: #FFFFFF;
      &:hover {
        background: #8c3b2f;
        border-radius: 24px; } } }

  &-time {
    &__input {
      margin-left: 0!important; // can't override other ways
      & > input {
        padding: 10px 33px;
        font-size: 14px;
        line-height: 16px;
        width: 107px;
        text-align: center;
        border: 1px solid #CCDFE5;
        border-radius: 8px;
        outline: none;
        font-family: Roboto, sans-serif;
        &::-webkit-calendar-picker-indicator { // removing system picker
          display: none; } } } } }
