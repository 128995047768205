.top-dash-editor {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .react-grid-layout {
        width: 100%;
        .widget {
            outline: 1px grey dashed;
            .delete-top-dash {
                position: absolute;
                top: 0px;
                left: calc( 100% - 15px );
                &:hover {
                    transform: scale(1.3);
                    & path {
                        stroke: #C15747; } } } } } }
