.supervisor-dashboard-content-nav {
    padding: 16px;
    width: 100%;
    height: 88px;
    display: flex;
    align-items: center;
    &-tab {
        cursor: pointer;
        padding: 16px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #A1BDCA;
        &:hover {
            color: #394145; }
        &.selected {
            color: #394145; } } }
