.dashboard-editor {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .react-grid-layout {
        overflow: scroll;
        height: 100%;
        .widget {
            .delete-dash {
                position: absolute;
                top: 20px;
                left: calc( 100% - 45px );
                &:hover {
                    transform: scale(1.3);
                    & path {
                        stroke: #C15747; } } } } } }

