.ad-node {
    &-default {
        width: 324px;
        background: #fff;
        border: 1px solid #F4FAFB;
        padding: 15px;
        border-radius: 12px;
        box-shadow: 0 8px 12px rgba(5, 109, 160, 0.1);
        cursor: default;
        &-callback {
            height: 140px; } }

    &-input-field {
        margin-top: 20px;
        &:hover {
            border: 1px solid #CCDFE5; } } }
