.ad-node-start {
    padding: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 168px;
    height: 72px;
    border-radius: 76px;
    background: #fff;
    border: 1px solid #F4FAFB;
    box-shadow: 0 8px 12px rgba(5, 109, 160, .1);
    &:active {
        cursor: grabbing; }
    &-icon {
        width: 48px;
        height: 48px;
        background: #AD3928;
        border-radius: 32px;
        display: flex;
        justify-content: center;
        align-items: center; }
    &-text {
        display: flex;
        justify-content: center;
        color: #142328;
        font-weight: 700;
        font-size: 20px;
        width: 100px; } }
