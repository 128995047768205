.planned-calls-container {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 16px;
    .planned-calls-header {
        width: 304px;
        height: 80px;
        span {
            display: inline-block;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            margin-left: 32px;
            margin-top: 20px; } }
    .planned-calls-content {
        width: 100%;
        height: calc(100% - 64px);
        padding-left: 32px;
        padding-right: 32px;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #142328; } }
    .total,.for {
        .total-counters,.for-counters {
            display: flex;
            margin-top: 8px;
            h3 {
                display: block;
                margin: 0px 0px 0px 16px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 56px;
                color: #556268; } } } }
