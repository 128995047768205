.node-button {
    width: calc(100% + 10px);
    height: calc(100% - 30px);
    margin: 0 -5px;
    display: flex;
    align-items: flex-end;
    position: relative;
    &-wrapper {
        display: flex;
        padding: 16px 5px;
        align-items: center;
        width: 100%;
        background: transparent;
        transition: .2s;
        border-radius: 8px;
        cursor: pointer;
        justify-content: space-between;
        &:hover {
            background: #F4FAFB;
            & .node-button-right {
                opacity: 1; } }
        &-add:hover {
            background: #FFEDEA; }
        &-input:hover {
            background: transparent; } }


    &-left {
        display: flex;
        align-items: center;
        &-input {
            height: 20px;
            width: 100%;
            margin-bottom: 10px;
            justify-content: space-between; } }
    &-right {
        opacity: 0;
        transition: .2s;
        & svg {
            background: transparent;
            &:active {
                background: transparent; }
            &:hover path {
                stroke: #A1BDCA; } } }

    &-icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        & svg {
            width: 20px;
            height: 20px;
            & path {
                stroke: #c15747; } } }

    &-text {
        color: #142328;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        &-add {
            color: #AD3928; } } }

.accept-wrapper {
    display: flex; }
