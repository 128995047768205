.script-content {
    &-wrapper-expanded {
        background-color: #BA4433;
        max-width: 336px;
        min-width: 336px;
        transition: .3s;
        position: relative; }
    &-wrapper-collapsed {
        background-color: #BA4433;
        max-width: 336px;
        min-width: 336px;
        transition: .3s;
        margin-right: -336px;
        position: relative; }
    &-body {
        padding: 32px;
        color: #FFFFFF; } }

.script-call-buttons {
    position: absolute;
    bottom: 40px;
    left: 132px;
    display: flex;
    justify-content: center;
    &-center-button {
        margin: 0 28px; } }
