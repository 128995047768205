.calls-container {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 16px;
    .calls-header {
      width: 100%;
      height: 64px;
      span {
        display: inline-block;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        margin-left: 32px;
        margin-top: 20px; } }
    .calls-content {
      height: calc(100% - 64px);
      align-items: center;
      display: flex;
      padding: 0px 32px;
      .total {
        display: flex;
        flex-direction: column;
        &-counters {
          display: flex;
          margin-top: 4px;
          svg {
            margin-right: 16px; }
          span {
            display: inline-block;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 56px;
            color: #556268; } } }
      &-counters {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        &-content {
          display: flex;
          flex-direction: column;
          span:last-child {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 56px;
            color: #556268;
            margin-top: 4px; }
          &:first-child span:last-child {
            color: #31A26B; } } } } }


