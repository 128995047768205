.navigator {
    &-container {
        cursor: pointer;
        width: 64px;
        height: 64px;
        position: relative;
        box-shadow: inset -1px 0 0 #ebf5f7;
        transition: .3s;
        &:hover {
            background: #f4fafb; }

        .navigator-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%); }

        .sider-notifier {
            height: 20px;
            min-width: 20px;
            text-align: center;
            position: absolute;
            top: 12px;
            right: 12px;
            &-big {
                right: 6px; } } }

    &-active {
        box-shadow: inset 4px 0 0 #ad3928;
        transition: 0s;
        &:hover {
            background: transparent; } } }
