.operator-statuses-container {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: #FFFFFF;

    .operator-statuses-header span {
        display: inline-block;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        margin-left: 32px;
        margin-top: 20px;
        margin-bottom: 20px; }
    .operator-statuses-content {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .round-chart-container {
            width: 192px;
            height: 192px; }
        .operator-statuses-data {
            flex-wrap: wrap;
            display: flex;
            margin: 20px 32px;
            .data {
                width: 50%;
                padding: 8px;
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                span {
                    width: inherit;
                    display: inline-block;
                    font-size: 16px;
                    margin-left: 10px;
                    justify-self: center; }
                .round {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: black; } } } } }
