.supervisor-campaign-content-header {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #A1BDCA;
    display: flex;

    &-button {
        cursor: pointer;
        margin: 31px 56px 32px 32px;

        &-active {
            margin: 31px 56px 32px 32px;
            color: #394145; } } }

.supervisor-campaign-content-content {
    padding: 0 24px; }
