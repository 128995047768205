.node-add-action {
    position: absolute;
    z-index: 1;
    right: 16px;
    display: flex;
    align-items: center;
    background: #AD3928;
    padding: 8px 0 8px 16px;
    width: 200px;
    height: 40px;
    border-radius: 26px;
    cursor: pointer;
    transition: .2s;
    box-shadow: 0 8px 12px rgba(5, 109, 160, .15);
    &:hover {
        background: #BD4936FF; }
    &-active {
        box-shadow: 0 0 0 rgba(5, 109, 160, .15); }
    &-title {
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        letter-spacing: -0.02em; }
    &-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        & svg path {
            stroke: #fff;
            transition: .2s; } }

    &-content {
        position: absolute;
        z-index: 0;
        padding-top: 20px;
        padding-bottom: 16px;
        right: 16px;
        bottom: -136px;
        width: 200px;
        background: #AD3928;
        border-radius: 0 0 16px 16px;
        box-shadow: 0 8px 12px rgba(5, 109, 160, .15);
        &-el {
            padding-left: 16px;
            color: #fff;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.02em;
            cursor: pointer;
            padding-top: 4px;
            padding-bottom: 4px;
            transition: .2s;
            &:hover {
                background: #BD4936FF; }
            &-disabled {
                cursor: not-allowed; } } }

    &-tooltip {
        background: #AD3928 !important;
        color: #fff !important;
        border-radius: 4px !important;
        font-weight: 400;
        font-size: 12px !important;
        z-index: 32 !important;
        opacity: 1 !important; } }
