
.campaign-header {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 12px 32px;
    justify-content: space-between;
    &-left {
        color: black;
        font-family: 'Roboto', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px; }

    &-right {
        display: flex;
        justify-content: space-between;
        width: 100px; } }

.header-icon {
    cursor: pointer;
    &:hover {
        background: #FFEDEA; } }
