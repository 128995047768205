.operators-dd {
    &-container {
        margin-right: 32px;
        min-width: 229px;
        min-height: 40px;
        background: #EBF5F7;
        border-radius: 12px; }
    &-header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        user-select: none;
        padding-inline: 5px;
        &:hover {
            background: #a5d6e2;
            border-radius: 12px; } }

    &-content {
        display: none;
        position: relative;
        background: #EBF5F7;
        border-radius: 12px;
        margin-top: 6px;
        &.open {
            z-index: 4;
            display: flex;
            flex-direction: column;
            overflow: hidden; }
        &-item {
            display: flex;
            align-items: center;
            height: 40px;
            user-select: none;
            padding: 4px 6px;
            &:hover {
                background: #a5d6e2; } } } }
