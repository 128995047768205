.text__style {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px; }

.node-message {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    &-title {
        color: #556268;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 12px; }
    &-body {
        @extend .text__style;
        width: 100% !important;
        height: auto;
        outline: none;
        resize: none;
        padding: 8px 16px;
        color: #142328;
        border: 1px solid #CCDFE5;
        border-radius: 8px;
        transition: .3s;
        min-width: 10px;
        margin-bottom: 12px;
        &::placeholder {
            @extend .text__style;
            color: #A1BDCA; }
        &:focus, &:hover {
            border: 1px solid #C15747; } } }
