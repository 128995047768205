.button,
.button-outline {
    cursor: pointer;
    border-radius: 12px;
    height: 38px;
    border: 1px solid #c15747;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; }

.button-outline {
    background: #fff; }

.button {
    background: #c15747;

    &-text,
    &-outline-text {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px; }

    &-text {
        color: white; }

    &-outline-text {
        color: #c15747; }

    &-disabled {
        cursor: not-allowed; } }
