.chat-list-container {
    background: #FFFFFF;
    height: 100%;
    width: 353px;
    overflow: auto;

    .tab-buttons {
        display: flex;
        border-bottom: 2px solid #dee2e6; }

    .tab-button {
        flex: 1;
        padding: 8px 16px;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 5px 5px 0 0;
        background-color: #f8f9fa;
        margin-bottom: -2px;
        &:hover {
            background-color: #e9ecef; }

        &.active {
            background-color: #fff;
            border-color: #dee2e6 #dee2e6 transparent;
            border-bottom: 2px solid white; } }

    .chat-list {
        padding: 16px; } }

