.radio-button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    width: 272px;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 0 24px;

    span {
        line-height: 24px;
        font-family: Roboto, serif;
        font-weight: 700;
        font-size: 16px;
        color: #397FBF; }

    div {
        color: #AD3928;
        top: 21px;
        right: 24px; } }

.radio-button {
    display: none; }

.radio-button:active {
    outline: none;
    border: none; }

.red-text {
    color: #AD3928!important; }
