.action-buttons {
  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 8px;
    left: 0;
    width: 100%;
    padding: 24px 26px;
    font-size: 12px; }
  &-left {
    color: #545454;
    cursor: pointer;
    padding: 4px 0; }
  &-right {
    cursor: pointer;
    color: #fff;
    background: #C15747;
    border-radius: 12px;
    padding: 4px 16px; } }
