.ad-table {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 0 0 24px;
    &-header {
        height: 64px;
        display: flex;
        padding: 0 32px;
        &-col {
            width: 38%;
            height: 64px;
            display: flex;
            font-weight: 700;
            font-size: 16px;
            letter-spacing: -0.02em;
            color: #142328;
            align-items: center;
            &-wrapper {
                display: flex;
                align-items: center;
                cursor: pointer; }
            &-short {
                @extend .ad-table-header-col;
                width: 24%; }
            &-arrows {
                display: flex;
                flex-direction: column;
                width: 6px;
                height: 12px;
                justify-content: space-between;
                margin-left: 8px; } } }

    &-body {
        overflow-y: auto;
        width: 100%;
        max-height: calc(100vh - 264px);
        &-row {
            padding: 0 32px;
            display: flex;
            height: 64px;
            box-shadow: inset 0px -1px 0px #EBF5F7;
            transition: .3s;
            &-last {
                @extend .ad-table-body-row;
                box-shadow: none; }
            &-col {
                display: flex;
                width: 38%;
                height: 64px;
                align-items: center;
                &-short {
                    @extend .ad-table-body-row-col;
                    justify-content: space-between;
                    width: 24%; }
                &-value {
                    padding-right: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis; }
                &-tag {
                    display: flex; }
                &-icons {
                    display: flex; }
                &-icon {
                    width: 32px;
                    height: 32px;
                    cursor: pointer;
                    background: transparent;
                    border-radius: 12px;
                    padding: 4px;
                    transition: .3s;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .ad-icon-close-circle {
                        width: 20px;
                        height: 20px; }
                    .ad-icon-close-circle path, .ad-icon-chat-circle path {
                        transition: .3s; }
                    &:hover {
                        background: #FFEDEA;
                        & .ad-icon-close-circle path {
                            fill: #C15747; }
                        & .ad-icon-chat-circle path {
                            stroke: #C15747; } } } }

            &:hover {
                background: #F4FAFB; }
            &-chosen {
                background: #F4FAFB;
                box-shadow: inset 4px 0 0 #ad3928; } } }

    &-error {
        padding-top: 24px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        letter-spacing: -0.02em;
        color: #142328; }

    &-margin {
        margin-left: 12px; } }
