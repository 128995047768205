.script-sider-content {
    padding-top: 8px;
    display: flex;
    flex-direction: column;

    &-search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        margin-bottom: 20px;
        & .search-wrapper {
            width: 248px;
            margin-right: 8px; }
        & .icon-wrapper {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer; } }

    &-filling {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        height: calc(100vh - 204px);
        & .sider-dd {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 11px 24px;
            margin-bottom: 2px;
            &:hover {
                background: #FFEDEA; }

            &-wrapper {
                display: flex;
                align-items: center; }
            &-logo {
                width: 24px;
                height: 24px;
                margin-right: 8px; }
            &-text {
                color: #142328;
                font-weight: 400;
                font-size: 16px; }
            &-arrow {
                display: flex;
                align-items: center;
                justify-content: center;
                & svg {
                    & path {
                        transition: .3s; } } }

            &-container {
                margin-top: 16px;
                margin-bottom: 8px;
                transition: .5s;
                & .dd-el {
                    display: flex;
                    margin-bottom: 8px;
                    cursor: pointer;
                    padding: 8px 0 8px 56px;
                    align-items: center;
                    justify-content: space-between;
                    &:hover {
                        background: #FFEDEA; }
                    &-active {
                        background: #FFEDEA;
                        box-shadow: inset 4px 0 0 #AD3928; }

                    &-left {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        &-text {
                            color: #142328;
                            font-weight: 400;
                            font-size: 16px;
                            &-script {
                                padding: 4px 0; } }

                        &-status {
                            font-weight: 400;
                            font-size: 12px;
                            &-green {
                                color: #31A16B; }
                            &-red {
                                color: #C15747; } } }

                    &-right {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #c15747;
                        margin-right: 32px; } } } } } }
