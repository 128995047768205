.notification-container {
    position: absolute;
    z-index: 4;
    &-login {
        top: 30px;
        right: 30px; }
    &-main {
        top: 64px;
        right: 368px; }
    &-super {
        right: 30px; }
    &-close {
        position: absolute;
        top: -32px;
        left: 20px;
        height: 24px;
        width: 24px;
        padding: 4px;
        border-radius: 50%;
        background: #ccdfe5;
        box-shadow: 0 11px 23px rgba(36, 0, 0, 0.09);
        cursor: pointer; } }
