.message-with-avatar {
  display: flex;
  &-clickable {
    cursor: pointer; }
  &-sender {
    display: flex;
    align-self: flex-end; }
  &-income {
    align-self: flex-start;
    .message-sender-name {
      padding: 4px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #A1BDCA; } } }

.message-container {
  max-width: 350px;
  border-radius: 16px 16px 4px 16px;
  padding: 12px 16px 12px 16px;
  background: #FFFFFF;
  border: none;
  margin-bottom: 5px;
  margin-right: 12px;
  display: flex;
  &-text {
    word-break: break-word; }
  &-time {
    color: #BA4433;
    position: relative;
    margin-left: 10px;
    align-self: flex-end; } }
