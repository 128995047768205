.ad {
    &-node-header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        &-left {
            display: flex;
            align-items: center; }
        &-icon {
            display: flex;
            margin-right: 8px;
            width: 24px;
            height: 24px;
            align-items: center;
            justify-content: center;
            & svg path {
                stroke: #c15747; } }

        &-title {
            width: 193px;
            color: #142328;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }

        &-tools {
            height: 28px;
            width: 72px;
            display: flex;
            justify-content: flex-end;
            align-items: center; }

        &-input {
            position: absolute;
            top: -20px;
            left: 44px;
            width: 186px; } }

    &-drag-icon {
        cursor: grab;
        padding: 2px;
        &:active {
            cursor: grabbing; } }
    &-trash-icon, &-pencil-icon {
        cursor: pointer;
        border-radius: 6px;
        background: transparent;
        padding: 2px;
        transition: .3s;
        &:active {
            background: #FFEDEA;
            & path {
                stroke: #C15747; } }
        & path {
            transition: .3s; }
        &:hover path {
            stroke: #C15747; } } }

