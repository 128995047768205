.profile-modal-button {
    &-inner-container {
        padding: 8px;
        height: 40px;
        position: relative;
        cursor: pointer; }

    &-text,
    &-icon {
        display: inline-block; }

    &-icon {
        margin: 0 8px; }

    &-text {
        position: absolute;
        font-size: 16px;
        line-height: 24px;
        height: 24px;
        top: 8px;
        right: 16px;
        color: #c15747; } }

.disabled {
    color: #a1bdca;
    cursor: default; }
