.supervisor-group-chats-info {
    width: 100%;
    height: 100%;
    background: white;
    &-header {
        .header-info {
            padding: 16px 24px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #142328; }
        &-content {
            display: flex;
            align-items: center;
            padding: 16px 24px;
            .header-content-info {
                margin-left: 12px;
                display: flex;
                flex-direction: column;
                .group-info {
                    display: flex;
                    align-items: center;
                    .group-name {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.02em;
                        color: #142328;
                        margin-right: 4px; }
                    .group-id {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #A1BDCA; } }
                .group-members-count {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #A1BDCA; } } } }
    &-content {
        padding: 24px;
        &-header {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            .members-count {
                margin-left: 8px; } }
        .group-member {
            display: flex;
            align-items: center;
            padding: 8px;

            .member-initials {
                line-height: 48px;
                margin-right: 12px;
                width: 48px;
                height: 48px;
                background: #C15747;
                border-radius: 50%;
                text-align: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                color: #FFFFFF; }
            .member-info {
                display: flex;
                flex-direction: column;
                .member-name {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: #142328; }
                .member-role {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    display: flex;
                    align-items: flex-end;
                    color: #A1BDCA; } } } } }

