.script-sider-header {
    width: 100%;
    height: 64px;
    padding: 20px 24px 20px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-text {
        margin: 0;
        padding: 0;
        color: #142328;
        font-weight: 700;
        font-size: 20px; }

    &-add {
        width: 24px;
        height: 24px;
        cursor: pointer; } }

