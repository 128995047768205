.ad-pallet {
    z-index: 30;
    position: absolute;
    bottom: 35px;
    left: 32px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 8px 12px rgba(5, 109, 160, 0.1);
    padding: 4px 4px 0;

    &-button {
        cursor: pointer;
        width: 48px;
        height: 48px;
        border-radius: 8px;
        background: transparent;
        transition: .3s;
        margin-bottom: 4px;
        padding: 12px;
        &:hover {
            background: #FFEDEA; }

        & svg path {
            stroke: #394145; }
        &:hover svg path {
            stroke: #C15747; } }

    &-tooltip {
        background: #AD3928 !important;
        color: #fff !important;
        border-radius: 4px !important;
        font-weight: 400;
        font-size: 12px !important;
        z-index: 32 !important;
        opacity: 1 !important;
        margin-left: 15px !important; } }
