.view-content {
    height: 100%;
    width: 100%;
    position: absolute; }


.content-with-script-wrapper {
    display: flex;
    height: calc(100% - 64px); }


.content-with-script-content {
    flex-grow: 1; }
