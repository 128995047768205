.rectangle-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    width: 272px;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 0 24px;
    border: none;
    font-family: Roboto, serif;
    font-weight: 700;
    font-size: 16px;

    .preview-span {
        color: #397FBF; }

    span {
        text-align: left;
        line-height: 24px;
        font-family: Roboto, serif;
        font-weight: 700;
        color: #AD3928; }

    div {
        color: #AD3928; } }
