.tooltip {
    position: relative;
    display: inline-block;
    &-text {
        visibility: hidden;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 4px;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        opacity: 0.8;
        margin-left: 56px;
        top: 30%;
        white-space: nowrap; }
    &:hover &-text {
        visibility: visible; } }
