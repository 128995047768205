.notfound {
    height: 100vh;
    width: 100vw;

    &-wrapper {
        width: 50vw;
        height: 50vh;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%); }

    &-text {
        color: #142328;
        transform: scaleY(1.1);
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 100px; }

    &-button {
        &-wrapper {
            max-width: 500px;
            margin: auto; }
        &-bottom-margin {
            margin-bottom: 20px; } } }
