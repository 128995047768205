.info-item {

    .inverted {
        color: #fff; } }

.info-item-title {
    color: #A1BDCA;
    line-height: 16px; }


.info-item-data {
    color: #394145;
    line-height: 24px;
    font-size: 16px;
    display: flex;
    align-items: center;
    &-active {
        color: #C15747;
        line-height: 24px;
        font-size: 16px; }
    &-icon {
         margin-right: 6px; } }
