.script {
    &-main-content {
        display: flex;
        flex-grow: 1;
        height: 100%;

        &-left {
            width: 336px;
            background: #fff;
            box-shadow: inset -1px 0px 0px #EBF5F7; }

        &-right {
            width: calc(100% - 336px); } }

    &-subheader {
        display: flex;
        flex-grow: 1;

        &-sider {
            width: 336px;
            background: #fff;
            box-shadow: inset -1px 0 0 #EBF5F7; }

        &-main {
            width: calc(100% - 336px);
            background: #fff;
            box-shadow: inset 0px -1px 0px #EBF5F7, inset 0px -1px 0px #F4FAFB; } }

    &-campaign-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 30;
        background: rgba(0,0,0,0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        & > div {
            width: 50%; } } }
