.average-calls-container {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 16px;
    .average-calls-header {
      width: 100%;
      height: 64px;
      span {
        display: inline-block;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        margin-left: 32px;
        margin-top: 20px; } }
    .average-calls-content {
        display: flex;
        padding: 0px 32px;
        height: calc(100% - 64px);
        align-items: center;
        &-counters {
            width: 50%;
            &-data {
                padding-top: 16px;
                display: flex;
                span {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 56px;
                    color: #394145; }
                svg {
                    margin-right: 16px; } }
            &:first-child {
                border-right: 1px solid #EBF5F7; }
            &:last-child {
                padding-left: 32px; } } } }

