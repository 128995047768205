.campaign-modal-container {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #FFFFFF;
    border-radius: 24px;
    margin-top: 32px;
    margin-left: 32px;
    &-title {
        color: #142328;
        font-family: Roboto, serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 32px; }
    & span {
        font-family: Roboto, serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left; }
    &-description {
        color: #556268;
        font-family: Roboto, serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 16px; }
    &-type {
        margin-top: 24px; } }
