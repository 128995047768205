.chat-subheader {
  display: flex;
  padding: 12px 32px;
  flex-grow: 1;
  background: #FFFFFF;
  align-items: center;
  height: 64px;
  box-shadow: 0px -1px 0px 0px #EBF5F7 inset;

  & .input-wrapper {
    margin: 0;
    width: 100%;
    & input {
      margin: 0; } }

  &-summary {
    display: flex;
    flex-grow: 1;
    & div {
      margin-right: 8px; }
    &-icons {
      display: flex;
      justify-content: center;
      align-items: center; }
    &-icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: transparent;
      cursor: pointer;
      &:hover {
        background: #FFEDEA;
        & svg {
          & path {
            stroke: #C15747; }
          & .ad-info-icon-dot {
            stroke: none;
            fill: #C15747; } } } } }

  &-profile-picture {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    background: #AD3928;
    display: flex;
    justify-content: center;
    align-items: center; } }

.info-item-title {
  color: #394145; }
