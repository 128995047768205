.settings-subheader {
    height: 64px;
    width: 100%;
    background: #fff;
    &-title {
        padding-inline: 32px;
        width: 336px;
        height: 100%;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 64px;
        color: #142328;
        border-right: 1px solid #EBF5F7;
        border-bottom: 1px solid #EBF5F7; } }
