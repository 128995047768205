.tags-editor {
  padding: 32px;
  &-color-map {
    display: flex;
    justify-content: space-between; }
  &-header {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 24px;
    color: #394145;
    margin-bottom: 32px;
    & svg {
      margin: 0px 8px;
      cursor: pointer;
      &:hover {
        transform: scale(1.3);
        & path {
          stroke: #C15747; } } } }
  &-wrapper {
    padding: 24px;
    border-radius: 12px;
    background: #FFFFFF;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    height: fit-content;
    &-header {
      font-size: 14px;
      color: #556268;
      width: 100%; } }
  &-item {
    margin: 16px 16px 0 0; } }
