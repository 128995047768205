.editor-panel {
    &-wrapper {
        padding: 8px 24px; }
    &-content {
        width: 50%;
        height: 60px;
        background: #fff;
        border-radius: 8px;
        display: flex;
        align-items: center;
        & svg {
            margin: 0px 8px;
            &:hover {
                transform: scale(1.3);
                & path {
                    stroke: #C15747; } }
            &:last-of-type {
                margin-left: auto; } } } }
