.notification {
    position: relative;
    background: #fff;
    min-height: 64px;
    width: 400px;
    padding: 8px;
    box-shadow: 0 11px 23px rgba(36, 0, 0, 0.09);
    border-radius: 16px;
    margin-bottom: 8px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    &-icon {
        width: 48px;
        height: 48px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        &-background-grey {
            background: #CCDFE5; }
        &-background-green {
            background: #31A26B; }
        &-background-red {
            background: #C15747; }
        &-background-blue {
            background: #286DAD; }
        &-background-orange {
            background: #F6C545; } }

    &-articles {
        height: 44px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-left: 12px;
        &-title {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.02em;
            color: #142328;
            margin: 0 0 4px; }
        &-body {
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #556268;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; } } }

.agent-request {
    &-buttons {
        display: flex; }
    &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 32px;
        width: 32px;
        border-radius: 8px; } }
.accept {
    background: #E4FFF2;
    border: 1px solid #06C167;
    margin: 0 8px; }

.decline {
    background: #FFEDEA;
    border: 1px solid #C15747; }
