.ad-node {
    &-accept, &-decline {
        cursor: pointer;
        background: transparent;
        border-radius: 8px;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        &:active {
            background: #FFEDEA; } } }
