.notifier-container {
    width: 100%;
    height: 100%;
    min-width: 20px;
    height: 20px;
    border-radius: 20px;
    background: #c15747;
    padding: 0 2px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center; }

