//Overwrite

.react-flow__ {
    &minimap-mask {
        stroke: #C15747;
        stroke-width: 100;
        fill-rule: evenodd;
        fill: #C15747;
        stroke-linejoin: round;
        opacity: .2;
        transform: scale(.8) translateX(40%) translateY(30%);
        outline: 2px solid #C15747;
        border-radius: 28px; }

    &attribution {
        display: none;
        bottom: -100px !important; }

    &controls {
        bottom: 35px !important;
        left: auto !important;
        right: 85px !important;
        box-shadow: 0 8px 12px rgba(5, 109, 160, 0.1) !important;
        background: transparent;
        & button {
            position: absolute;
            width: 48px;
            height: 48px;
            padding: 0;
            border: 0;
            box-shadow: 0 8px 12px rgba(5, 109, 160, 0.1) !important;
            background: #943005;
            &:hover {
                background: #a83806; } }
        &-zoomin {
            top: -97px;
            border-radius: 8px 8px 0 0;
            border-bottom: 1px solid #C15747 !important;
            background-image: url('../../../components/Icons/ScriptDev/ScriptControls/Plus.svg') !important;
            background-repeat: no-repeat !important;
            background-position: center !important; }
        &-zoomout {
            top: -48px;
            border-radius: 0 0 8px 8px;
            background-image: url('../../../components/Icons/ScriptDev/ScriptControls/Minus.svg') !important;
            background-repeat: no-repeat !important;
            background-position: center !important; }
        &-fitview {
            border-radius: 50%;
            top: -150px;
            background-image: url('../../../components/Icons/ScriptDev/ScriptControls/CrosshairSimple.svg') !important;
            background-repeat: no-repeat !important;
            background-position: center !important; }

        & button svg {
            display: none; } }

    &edge-path {
        stroke: #A1BDCA !important;
        cursor: pointer; }



    &edge.selected &edge-path {
        stroke-width: 2px !important; }
    &edge:hover &edge-path {
        stroke-width: 2px !important; }

    &arrowhead {
        & polyline {
            fill: #A1BDCA !important;
            stroke: #A1BDCA !important; } } }

//Styles

.ad {
    &-minimap {
        border-radius: 12px;
        box-shadow: 0 16px 12px rgba(5, 109, 160, 0.1);
        bottom: 35px !important;
        right: 122px !important; }

    &-handle {
        background: #AD3928 !important;
        border: 2px solid #EBF5F7 !important;
        height: 16px !important;
        width: 16px !important;
        outline: 3px solid #fff;
        outline-offset: -8px;
        &-right {
            right: -18px !important; }
        &-left {
            left: -8px !important; }
        &-start {
            right: -8px !important; } }

    &-edge {
        &-trash {
            cursor: pointer;
            background: #F4FAFB;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            display: none;
            justify-content: center;
            align-items: center;
            min-height: 24px;
            .react-flow__edge.selected & {
                display: flex; }
            .react-flow__edge:hover & {
                display: flex; }

            &:hover svg path {
                transition: .3s;
                stroke: #C15747; } } } }
