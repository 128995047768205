.script-header-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: 336px;
    min-width: 336px;
    padding: 12px 20px;
    background-color: #AD3928;
    cursor: pointer; }


.script-header-icon {
    margin: 0 12px; }

.script-header-arrow {
    margin: 0 0 0 32px;
    transition: .3s; }

.rotated {
    transform: rotate(180deg);
    margin-top: -6px; }
